// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const devicesSlice = createSlice({
  name: "devices",
  initialState: {
    allDevices: [],
    commonDevices: [],
    uncommonDevices: [],
    userDevices: [],
  },
  reducers: {
    handleGetDevices: (state, action) => {
      state.allDevices = action.payload;
      state.commonDevices = action.payload.filter(
        (item) => item.common === "1"
      );
      state.userDevices = action.payload.filter((item) => item.common === "1");
      state.uncommonDevices = action.payload.filter(
        (item) => item.common === "0"
      );
    },

    handleChangeDevicesQuantity: (state, action) => {
      let deviceId;
      let existingDeviceIndex;

      if (action.payload.appliance.newId) {
        deviceId = action.payload.appliance.newId;
        existingDeviceIndex = state.userDevices.findIndex(
          (item) => item.newId === deviceId
        );
      } else {
        deviceId = action.payload.appliance.id;
        existingDeviceIndex = state.userDevices.findIndex(
          (item) => item.id === deviceId
        );
      }

      const existingDevice = state.userDevices[existingDeviceIndex];

      const updatedUserDevices = [...state.userDevices];
      updatedUserDevices[existingDeviceIndex] = {
        ...existingDevice,
        quantity: action.payload.newQuantity,
      };

      state.userDevices = updatedUserDevices;
    },
    handleAddUserDevice: (state, action) => {
      state.userDevices.push(action.payload.appliance);
    },
    handleUpdateDevicesData: (state, action) => {
      let deviceId;
      let existingDeviceIndex;

      if (action.payload.appliance.newId) {
        deviceId = action.payload.appliance.newId;
        existingDeviceIndex = state.userDevices.findIndex(
          (item) => item.newId === deviceId
        );
      } else {
        deviceId = action.payload.appliance.id;
        existingDeviceIndex = state.userDevices.findIndex(
          (item) => item.id === deviceId
        );
      }

      const existingDevice = state.userDevices[existingDeviceIndex];

      const updatedUserDevices = [...state.userDevices];
      updatedUserDevices[existingDeviceIndex] = {
        ...existingDevice,
        label: action.payload.appliance.label,
        power: action.payload.appliance.power,
      };

      state.userDevices = updatedUserDevices;
    },
  },
});

export const {
  handleGetDevices,
  handleChangeDevicesQuantity,
  handleAddUserDevice,
  handleUpdateDevicesData,
} = devicesSlice.actions;

export default devicesSlice.reducer;
