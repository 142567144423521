// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

const initialUser = () => {
  const item = window.localStorage.getItem("userData");
  //** Parse stored json or if none return initialValue
  return item && typeof item === "object" ? JSON.parse(item) : {};
};

const initialUserToken = () => {
  const item = window.localStorage.getItem("token");
  //** Parse stored json or if none return initialValue
  return item ? item : {};
};

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    userData: initialUser(),
    token: initialUserToken(),
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload.userData;
      state.token = action.payload.token;
      // ** Add to user, accessToken & refreshToken to localStorage
      localStorage.setItem("userData", JSON.stringify(action.payload.userData));
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("loggedIn", true);
    },
    handleSaveLocation: (state, action) => {
      state.userData.city = action.payload.city;
      state.userData.city_id = action.payload.city_id;
      state.userData.country = action.payload.country;
      state.userData.country_id = action.payload.country_id;
      state.userData.timezone = action.payload.timezone;
      state.userData.latitude = action.payload.latitude;
      state.userData.longitude = action.payload.longitude;
      state.userData.location = `${action.payload.latitude},${action.payload.longitude}`;

      // ** Add to localStorage
      localStorage.setItem("userData", JSON.stringify(state.userData));
    },
    handleLogout: (state) => {
      state.userData = {};
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.setItem("loggedIn", false);
    },
    handleUpdateUser: (state, action) => {
      state.userData = action.payload.userData;
      // ** update user data in localStorage
      localStorage.setItem("userData", JSON.stringify(action.payload.userData));
    },
  },
});

export const {
  handleLogin,
  handleLogout,
  handleUpdateUser,
  handleSaveLocation,
} = authSlice.actions;

export default authSlice.reducer;
