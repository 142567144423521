// ** I18n Imports
import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n

  // Enables the i18next backend
  .use(Backend)

  // Enable automatic language detection
  .use(LanguageDetector) // https://github.com/i18next/i18next-browser-languageDetector

  // Enables the hook initialization module
  .use(initReactI18next)
  .init({
    // lng: "en", // language to use (overrides language detection). If set to 'cimode' the output text will be the key
    backend: {
      /* translation file path */
      loadPath: `${process.env.PUBLIC_URL}/assets/data/locales/{{lng}}.json`,
    },
    fallbackLng: "en", // language to use if translations in user language are not available.
    debug: false,
    keySeparator: false, //char to separate keys. If working with a flat JSON, it's recommended to set this to false.
    react: {
      useSuspense: true, // Not using Suspense you will need to handle the not ready state yourself by eg. render a loading component as long ready === false . Not doing so will result in rendering your translations before they loaded which will cause save missing be called although translations exists (just yet not loaded).
    },
    interpolation: {
      escapeValue: false, // escapes passed in values to avoid XSS injection, not needed for react!!
      formatSeparator: ",", // used to separate format from interpolation value
    },
  });

export default i18n;
