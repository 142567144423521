// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import auth from "./authentication";
import devices from "./devices";

const rootReducer = {
  auth,
  navbar,
  layout,
  devices,
};

export default rootReducer;
